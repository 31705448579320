<template>
  <div>
    <v-tabs
      v-model="days"
      grow
      active-class="white"
      background-color="#f4f3f3"
      color="green"
      @change="getTimeSlot(); changeColor(timeSlot[times]);"
    >
      <v-tabs-slider />

      <v-tab
        v-for="(period, index) in report.periods"
        :key="index"
      >
        <span v-if="report.reportType === 'trend'">{{ formattedDateTrend(period.label) }}</span>
        <span v-else>{{ formattedDate(period.fromDate) }}</span>
      </v-tab>

      <v-tab-item
        v-for="(period, index) in report.periods"
        :key="index"
      >
        <v-card flat>
          <v-card-text :class="$style['reportsHtml']">
            <!-- eslint-disable -->
            <div
              v-if="period.comment"
              v-html="period.comment"
            />
            <b v-if="period.currentSituation">Situazione attuale:</b>
            <div
              v-if="period.currentSituation"
              v-html="period.currentSituation"
            />
            <b v-if="period.nextHoursForecast">Previsione per le prossime ore:</b>
            <div
              v-if="period.nextHoursForecast"
              v-html="period.nextHoursForecast"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-tabs
      v-model="times"
      background-color="transparent"
      color="green"
      class="mb-5"
      grow
    >
      <v-tab
        v-for="(time, timeIndex) in timeSlot"
        :key="timeIndex"
        @change="changeColor(time)"
      >
        <span v-if="report.reportType === 'trend'">{{ time.split("_")[1] }}</span>
        <span v-else>{{ time }}</span>
      </v-tab>
    </v-tabs>

    <div :class="$style['map-btn']">
      <ChangeMapZoom />
      <ChangeMapStyle :map-style="map.mapStyle" />
    </div>
    <v-row style="height: 520px">
      <Map />
    </v-row>

    <v-tabs-items v-model="days">
      <v-tab-item
        v-for="(period, index) in report.periods"
        :key="index"
      >
        <v-card flat>
          <v-card-text class="px-0">
            <v-tabs
              v-if="templateHeaders.length > 1"
              v-model="locations"
              background-color="transparent"
              color="green"
              class="mb-5"
              grow
            >
              <v-tabs-slider />

              <v-tab
                v-for="(headers, t) in templateHeaders"
                :key="t"
              >
                {{ locationHeaders[t] }}
              </v-tab>
            </v-tabs>

            <v-tabs-items
              v-model="locations"
              class="mx-3"
            >
              <v-tab-item
                v-for="(headers, t) in templateHeaders"
                :key="t"
              >
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          v-for="(header, h) in headers"
                          :key="h"
                          class="text-center"
                          :style="h !== 0 ? 'background-color: #0da344; color: white' : 'background-color: #F1F1F1'"
                        >
                          {{ header }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, i) in tableItems"
                        :key="i"
                      >
                        <td
                          v-for="(header, h) in headers"
                          :key="h"
                          class="text-center py-5"
                          :style="h === 0 ? 'background-color: #F1F1F1' : ''"
                        >
                          <!-- eslint-disable-next-line vue/no-v-html -->
                          <div v-html="item[header]" />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <div class="py-4">
      <v-btn
        class="ms-3 allWidth"
        color="success"
        dark
        @click="downloadReport(report)"
      >
        {{ $t('Reports.pdf') }}
      </v-btn>

      <v-btn
        v-if="other_services.organization.digestLegend !== ''"
        outlined
        light
        class="ms-3 activeMarginTop allWidth"
        color="success"
        @click="downloadReportLegend()"
      >
        {{ $t('Reports.legend') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Map from '@/components/core/Map.vue';
import ChangeMapZoom from '@/components/utils/ChangeMapZoom.vue';
import ChangeMapStyle from '@/components/utils/ChangeMapStyle.vue';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { pointerMove } from 'ol/events/condition';
import { Vector as VectorLayer } from 'ol/layer';
import Collection from 'ol/Collection.js';
import Feature from 'ol/Feature.js';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point.js';
import Overlay from 'ol/Overlay';
import Select from 'ol/interaction/Select';
import Icon from 'ol/style/Icon.js';
import * as olExtent from 'ol/extent';
import moment from 'moment-business-days';

export default {
  components: {
    Map,
    ChangeMapZoom,
    ChangeMapStyle
  },
  props: {
    report: {
      type: Object,
      default: null
    },
    geojson: {
      type: Array,
      default: null
    },
    templateHeaders: {
      type: Array,
      default: null
    },
    locationHeaders: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      days: 0,
      times: 0,
      locations: 0,
      timeSlot: [],
      tableItems: []
    };
  },
  computed: {
    ...mapState(['other_services', 'map'])
  },
  async mounted() {
    await this.getTimeSlot();
    // for await (const obj of this.geojson) {
    //   if (obj.data) {
    //     await this.loadReportGEOJSON(obj.data);
    //   }
    // }
    const promises = [];
    this.geojson.forEach(item => promises.push(this.loadReportGEOJSON(item.data)));
    await Promise.all(promises);
    this.fitGEOJSONextent();
    this.changeColor(this.timeSlot[this.times]);
  },
  methods: {
    ...mapActions(['removeLayer', 'loadReportGEOJSON', 'fitGEOJSONextent', 'downloadReport', 'downloadReportLegend']),
    formattedDate(reportDate) {
      return moment(reportDate).format('ddd DD/MM/YYYY');
    },
    formattedDateTrend(dateString) {
      const [startDate, endDate] = dateString.split(' - ');

      const startDateToReturn = moment(startDate, 'DD/MM/YYYY').format('ddd DD/MM/YYYY');
      const endDateToReturn = moment(endDate, 'DD/MM/YYYY').format('ddd DD/MM/YYYY');

      return `${startDateToReturn} - ${endDateToReturn}`;
    },
    async getTimeSlot() {
      this.times = 0;
      this.timeSlot = [];
      this.tableItems = [];

      const tempArray = [];
      this.report.periods[this.days].forecasts.forEach(item => {
        const tempObj = {};
        const range = (this.report.reportType === 'trend') ?
          `${item.timeSlot.from.slice(0, -3)} - ${item.timeSlot.to.slice(0, -3)}_${item.timeSlot.label}` :
          `${item.timeSlot.from.slice(0, -3)} - ${item.timeSlot.to.slice(0, -3)}`;

        if (!this.timeSlot.includes(range) && !item.timeSlot.disabled) {
          this.timeSlot.push(range);
        }

        tempObj.Fascia = range;
        this.templateHeaders.forEach(headers => {
          headers.forEach(header => {
            if (header.trim().toLowerCase() === item.geographicArea.name.trim().toLowerCase()) {
              const iconUrl = item.iconUrl ? `<img src="${item.iconUrl}" style="max-width: 40px"/><br>` : '';
              const phenomenonCode = item.alertLevelCode ? `<b>${item.phenomenonName} grado ${item.alertLevelCode}</b>` : '<b>Nessuna allerta</b>';
              const probabilityPercentage = item.probabilityPercentage ? `<br><small>Probabilità ${item.probabilityPercentage}%</small>` : '';
              const accumulation = item.accumulation ? `<br><small>Accumulo ${item.accumulation}${item.accumulationType}</small>` : '';
              const note = item.note ? `<br><small>${item.note}</small>` : '';
              tempObj[header] = `${iconUrl}${phenomenonCode}${probabilityPercentage}${accumulation}${note}`;
            }
          });
        });
        tempArray.push(tempObj);
      });

      this.timeSlot.forEach(time => {
        const tableObj = (this.report.reportType === 'trend') ? { Fascia: time.split('_')[1] } : { Fascia: time };
        tempArray.map(item => {
          if (item.Fascia === time) {
            tableObj[Object.keys(item)[1]] = Object.values(item)[1];
          }
        });
        this.tableItems.push(tableObj);
      });
    },
    changeColor(time) {
      this.map.OLmap.getOverlays().forEach(overlay => {
        this.map.OLmap.removeOverlay(overlay);
      });
      const imageCoordinates = [];
      this.map.OLmap.getLayers().getArray().slice(1).forEach(layer => {
        if (layer.get('layer_name') !== 'ALERT ICON') {
          let color = '#0E7B2D';
          let rgba = 'rgba(14,123,45,0.3)';
          const layerObj = {
            name: layer.values_.layer_name,
            coords: olExtent.getCenter(layer.getSource().getExtent()),
            alert: '',
            icon: '',
            info: {
              iconUrl: '<img src="https://placehold.co/40/0da344/fff.png?text=Err&font=opensans" style="width: 40px"/><br>',
              phenomenonCode: '<b>-</b>',
              probabilityPercentage: '',
              accumulation: '',
              note: ''
            }
          };
          this.report.periods[this.days].forecasts.forEach(item => {
            if (layer.values_.layer_name.replace(/\s+/g, ' ').trim().toLowerCase() === item.geographicArea.name.replace(/\s+/g, ' ').trim().toLowerCase() && item.timeSlot.from.slice(0, -3).split('_')[0] === time.split(' ')[0]) {
              layerObj.alert = item.alertLevelCode;
              layerObj.icon = item.iconUrl;
              layerObj.info = {
                iconUrl: item.iconUrl ? `<img src="${item.iconUrl}" style="max-width: 40px"/><br>` : '',
                phenomenonCode: item.alertLevelCode ? `${item.phenomenonName} grado ${item.alertLevelCode}` : 'Nessuna allerta',
                probabilityPercentage: item.probabilityPercentage ? `<br>Probabilità ${item.probabilityPercentage}%` : '',
                accumulation: item.accumulation ? `<br>Accumulo ${item.accumulation}${item.accumulationType}` : '',
                note: item.note ? `<br>Note: ${item.note}` : ''
              };

              switch (item.alertLevelCode) {
                case '1':
                  color = '#FAD100';
                  rgba = 'rgba(250,209,0,0.3)';
                  break;
                case '2':
                  color = '#F18300';
                  rgba = 'rgba(241,131,0,0.3)';
                  break;
                case '3':
                  color = '#EE1D24';
                  rgba = 'rgba(238,29,36,0.3)';
                  break;
                default:
                  break;
              }
            }
          });
          layer.getStyle().getStroke().setColor(color);
          layer.getStyle().getFill().setColor(rgba);
          layer.changed();
          imageCoordinates.push(layerObj);
        } else {
          this.removeLayer(layer.get('layer_name'));
        }
      });
      this.addImageFeatures(imageCoordinates);
    },
    addImageFeatures(coordinates) {
      const features = coordinates.map(obj => {
        const { name, coords, alert, icon, info } = obj;
        const feature = new Feature({
          geometry: new Point(coords)
        });
        feature.setProperties({
          name: name,
          coordinates: coords,
          alert_level: alert,
          icon: icon,
          info: info
        });

        return feature;
      });
      const featureCollection = new Collection(features);

      // const iconPng = (url) => {
      //   const urlSplit = url.split('/');
      //   const iconName = urlSplit.slice(-2).join('/');
      //   let imageUrl;

      //   try {
      //     imageUrl = require(`@/assets/reports/${iconName}`);
      //   } catch (e) {
      //     imageUrl = require('@/assets/reports/ok.png');
      //   }

      //   return imageUrl;
      // };

      const vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: featureCollection
        }),
        style: (feature) => {
          return feature.get('icon') === ''
            ? [new Style({
              image: new Circle({
                radius: 7,
                fill: new Fill({ color: 'rgba(13, 163, 68, .3)' }),
                stroke: new Stroke({ color: 'rgba(0, 0, 0, .1)', width: 2 })
              })
            })]
            : [new Style({
              image: new Icon({
                // src: iconPng(feature.get('icon'))
                src: feature.get('icon'),
                scale: 0.06
              })
            })];
        }
      });

      vectorLayer.set('layer_type', 'REPORTS');
      vectorLayer.set('layer_name', 'ALERT ICON');

      vectorLayer.setZIndex(10);

      this.map.OLmap.addLayer(vectorLayer);
      this.overlayConfig();
    },
    overlayConfig() {
      const overlay = new Overlay({
        element: document.createElement('div'),
        positioning: 'bottom-center',
        offset: [0, -30],
        stopEvent: false
      });
      this.map.OLmap.addOverlay(overlay);

      const hoverInteraction = new Select({
        layers: function (layer) {
          return layer.values_.layer_type === 'REPORTS' && layer.values_.layer_name === 'ALERT ICON';
        },
        condition: pointerMove,
        style: false
      });

      hoverInteraction.on('select', (event) => {
        const selectedFeatures = event.target.getFeatures();

        if (selectedFeatures.getLength() > 0) {
          const selectedFeature = selectedFeatures.item(0);
          overlay.setPosition(event.selected[0].getGeometry().flatCoordinates);
          const info = selectedFeature.get('info');
          const geographicArea = selectedFeature.get('name');
          overlay.getElement().innerHTML = `
            <style>
              .popup {
                display: flex;
                width: 250px;
                border-bottom: 1px dotted black;
                border-radius: 10px;
                background-color: rgba(255, 255, 255, 1);
                color: #000;
                padding: 10px;
              }
              .popup:hover {
                cursor: default;
              }
              .popup::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                opacity: 0.90;
                margin-left: -15px;
                border-width: 15px;
                border-style: solid;
                border-color: #fff transparent transparent transparent;
              }
            </style>
            <div class="popup">
              <div style="width: 70px">${info.iconUrl}</div>
              <div>
                <h1 style="margin-bottom: 10px">
                  ${geographicArea}
                </h1>
                ${info.phenomenonCode}${info.probabilityPercentage}${info.accumulation}${info.note}
              </div>
            </div>`;
          overlay.panIntoView();
        }
      });

      overlay.getElement().addEventListener('mouseleave', () => {
        overlay.setPosition(undefined);
      });

      this.map.OLmap.addInteraction(hoverInteraction);
    }
  }
};
</script>

<style module>
.v-tab--active {
  color: #0da344 !important;
}
.card-title {
  color: #0da344;
  background-color: #f4f3f3;
  font-size: 22px;
}
.map-btn {
  position: absolute;
  max-width: 4em;
  z-index: 2;
  margin: 20px 30px;
}

.reportsHtml p {
  margin: 0px !important;
}

.reportsHtml > div:not(:last-child){
  margin-bottom: 48px!important;
}

.reportsHtml > div{
  margin-top: 8px!important;
}
</style>

<style scoped>
@media (max-width: 630px) {
  .activeMarginTop {
    margin-top: 8px;
  }

  .allWidth {
    width: calc(100% - 24px);
  }
}
</style>
